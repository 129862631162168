<template>
  <b-overlay class="row" :show="loading">
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body">
        <div
          class="alert alert-warning text-center"
          v-if="settings.exists == 'no'"
        >
          <i class="fa fa-warning"></i> لم يتم ضبط الإعدادات بعد
        </div>
        <div class="form-group">
          <h5 for="">إسم المدرسة</h5>
          <input type="text" class="form-control" v-model="settings.name" />
        </div>
        <div class="form-group">
          <h5 for="">
            إسم المنطقة <span class="text-muted">(المنطقة التعليمية)</span>
          </h5>
          <input type="text" class="form-control" v-model="settings.manage" />
        </div>
        <div class="row g">
          <div class="col">
            <div class="form-group">
              <h5 for="">عدد الصفوف</h5>
              <input
                type="number"
                class="form-control"
                v-model="settings.classes_number"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <h5 for="">عدد مدرسي الإنتظار</h5>
              <input
                type="number"
                class="form-control"
                v-model="settings.waiting_teachers_number"
              />
            </div>
          </div>
        </div>
        <div class="row g">
          <div class="col">
            <div class="form-group">
              <h5 for="">الفسحة الأولى</h5>
              <select
                name=""
                class="form-control"
                v-model="settings.first_break"
                id=""
              >
                <option value="1">الأولى</option>
                <option value="2">الثانية</option>
                <option value="3">الثالثة</option>
                <option value="4">الرابعة</option>
                <option value="5">الخامسة</option>
                <option value="6">السادسة</option>
                <option value="7">السابعة</option>
                <option value="8">الثامنة</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <h5 for="">الفسحة الثانية</h5>
              <select
                name=""
                class="form-control"
                v-model="settings.second_break"
                id=""
              >
                <option value="1">الأولى</option>
                <option value="2">الثانية</option>
                <option value="3">الثالثة</option>
                <option value="4">الرابعة</option>
                <option value="5">الخامسة</option>
                <option value="6">السادسة</option>
                <option value="7">السابعة</option>
                <option value="8">الثامنة</option>
              </select>
            </div>
          </div>
        </div>
        <b-form-group label="عدد أيام الاسبوع" v-if="22 == 33">
          <b-form-radio-group
            v-model="settings.week_days"
            :options="week_days_array"
            class="demo-inline-spacing"
            name="radio-inline1"
          />
        </b-form-group>
        <b-form-group label="نوع المدرسة">
          <b-form-radio-group
            v-model="settings.type"
            :options="school_types_array"
            class="demo-inline-spacing"
            name="radio-inline2"
          />
        </b-form-group>
        <b-form-group label="">
          <b-form-radio-group
            v-model="settings.level"
            :options="school_types_array2"
            class="demo-inline-spacing"
            name="radio-inline3"
          />
        </b-form-group>
        <div class="col-12 text-center g">
          <button class="btn btn-relief-success" @click="save()">
            <i class="fa fa-save"></i>
            حفظ الإعدادات
          </button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BFormGroup, BFormRadioGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BFormGroup,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      settings: {},
      user: user,
      week_days_array: [
        { text: "5 أيام", value: 5 },
        { text: "6 أيام", value: 6 },
      ],
      school_types_array: [
        { text: "بنين", value: "boys" },
        { text: "بنات", value: "girls" },
      ],
      school_types_array2: [
        { text: "ابتدائي", value: "1" },
        { text: "متوسط", value: "2" },
        { text: "ثانوي", value: "3" },
      ],
    };
  },
  created() {
    var g = this;
    $.post(api + "/table/settings", {
      jwt: user.jwt,
      type: "general",
    })
      .then(function (r) {
        r = JSON.parse(r);
        g.settings = r;
        g.settings.name = r.name ? r.name : user.name;
        g.loading = false;
      })
      .catch(function () {
        swal.fire(
          "حدث خطأ اثناء الاتصال بالخادم",
          "برجاء المحاولة مرة أخرى",
          "warning"
        );
        g.loading = false;
      });
  },
  methods: {
    save() {
      var g = this;
      if (
        !this.settings.name ||
        !this.settings.manage ||
        !this.settings.classes_number ||
        !this.settings.first_break ||
        !this.settings.second_break ||
        !this.settings.waiting_teachers_number ||
        !this.settings.type ||
        !this.settings.level
      ) {
        swal.fire(
          "هناك حقول مطلوبة",
          "برجاء التأكد من ملئ كامل الحقول المطلوبة",
          "warning"
        );
      } else {
        g.loading = true;
        $.post(api + "/table/settings/save", {
          jwt: user.jwt,
          type: "general",
          value: JSON.stringify(this.settings),
        })
          .then(function (r) {
            g.loading = false;
            swal.fire("تم بنجاح", "", "success");
          })
          .catch(function () {
            swal.fire(
              "حدث خطأ اثناء الاتصال بالخادم",
              "برجاء المحاولة مرة أخرى",
              "warning"
            );
            g.loading = false;
          });
      }
    },
  },
};
</script>
